import React from "react"
import IntiativeAndEventsPreviousEpisodes from "./previous"
import IntiativeAndEventsUpcomingEpisodes from "./upcoming"

const IntiativesAndEventsEpisodes = ({ episodes }) => {
  const allTheEpisodes = episodes.map(episode => episode.node)

  const previousEpisodes = allTheEpisodes.filter(
    episode => episode.info.linkToRecording
  )

  const upcomingEpisodes = allTheEpisodes.filter(
    episode => !episode.info.linkToRecording
  )

  return (
    <>
      <IntiativeAndEventsUpcomingEpisodes episodes={upcomingEpisodes} />
      <IntiativeAndEventsPreviousEpisodes episodes={previousEpisodes} />
    </>
  )
}

export default IntiativesAndEventsEpisodes
