import React from "react"
import PagesLayout from "../../layout/pages"
import PageHeading from "../../utils/page-heading"
import IntiativesAndEventsEpisodes from "../episodes"
import { graphql } from "gatsby"

export const initiativeAndEvent = graphql`
  query singleInitiativeAndEventQuery($id: String) {
    wpIntiativeandEventCategory(id: { eq: $id }) {
      name
      description
      slug
      info {
        fieldGroupName
        flyer {
          localFile {
            childImageSharp {
              gatsbyImageData(formats: WEBP, placeholder: BLURRED)
            }
          }
        }
      }
    }

    allWpIntiativeAndEvent(
      filter: {
        intiatitivesandEventsCategory: {
          nodes: { elemMatch: { id: { eq: $id } } }
        }
      }
    ) {
      edges {
        node {
          title

          info {
            date
            description
            linkToRecording
            registrationLink

            flyer {
              localFile {
                childImageSharp {
                  gatsbyImageData(formats: WEBP, placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
  }
`

const InitiativesAndEventsSingle = ({ data }) => {
  const theInitiativeAndEvent = data.wpIntiativeandEventCategory
  const name = theInitiativeAndEvent.name
  const description = theInitiativeAndEvent.description

  const allEpisodes = data.allWpIntiativeAndEvent.edges
  return (
    <PagesLayout title={name}>
      <PageHeading text={name} />
      <p dangerouslySetInnerHTML={{ __html: description }}></p>

      <IntiativesAndEventsEpisodes episodes={allEpisodes} />
    </PagesLayout>
  )
}

export default InitiativesAndEventsSingle
