import React from "react"
import HyperLink from "../../../utils/HyperLink"

const UpcomingEpisode = ({ episode }) => {
  const title = episode.title
  const episode_info = episode.info
  const date = episode_info.date
  const description = episode_info.description
  const registrationLink = episode_info.registrationLink
  return (
    <section>
      <p className="md:text-p14 text-[10px] text-dd-black font-medium md:mb-4 mb-2 uppercase tracking-widest ">
        {date}
      </p>
      <h4 className="md:text-[24px] text-[16px] text-dd-black-60">{title}</h4>
      <p dangerouslySetInnerHTML={{ __html: description }} className="my-4"></p>
      <HyperLink text="Register Here" url={registrationLink} isExternal />
    </section>
  )
}

export default UpcomingEpisode
