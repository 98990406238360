import React from "react"
import PreviousEpisode from "./episode"

const IntiativeAndEventsPreviousEpisodes = ({ episodes }) => {
  const previousEpisodesMarkup = (
    <section className="mt-16">
      <h4 className="mt-8">Previous Episodes</h4>
      <hr className="mt-4" />

      <div className="grid md:grid-cols-2 grid-cols-1 gap-16">
        {episodes.map((episode, index) => (
          <PreviousEpisode episode={episode} key={index} />
        ))}
      </div>
    </section>
  )

  return episodes.length > 0 ? previousEpisodesMarkup : null
}

export default IntiativeAndEventsPreviousEpisodes
