import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import React from "react"

const PreviousEpisode = ({ episode }) => {
  const title = episode.title
  const episode_info = episode.info
  const date = episode_info.date
  const description = episode_info.description ? episode_info.description : ""
  const image = getImage(episode_info.flyer.localFile.childImageSharp)
  const linkToRecording = episode_info.linkToRecording

  return (
    <section>
      <p className="text-[10px] mb-2 uppercase tracking-widest ">{date}</p>
      <h5
        className={`md:text-[18px] text-[16px] ${
          description ? "" : "text-white"
        }`}
      >
        {title}
      </h5>
      {description && (
        <p
          dangerouslySetInnerHTML={{ __html: description }}
          className="md:mt-4 mt-2"
        ></p>
      )}

      <a
        href={linkToRecording}
        target="_blank noreferrer"
        rel="nofollow"
        className="relative block group bg-dd-black h-[120px] mt-8"
      >
        <GatsbyImage
          image={image}
          className=" h-full overflow-hidden  group-hover:opacity-70"
        />
        <StaticImage
          src="../../../../images/icons_logos/youtube_icon.png"
          className="absolute left-[50%] top-[50%] transform translate-x-[-50%] translate-y-[-50%]"
          alt=""
        />
      </a>
    </section>
  )
}

export default PreviousEpisode
