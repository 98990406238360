import React from "react"
import UpcomingEpisode from "./episode"

const IntiativeAndEventsUpcomingEpisodes = ({ episodes }) => {
  return (
    <section className="md:mt-32 mt-16 mb-16">
      {episodes.map((episode, index) => (
        <UpcomingEpisode episode={episode} key={index} />
      ))}
    </section>
  )
}

export default IntiativeAndEventsUpcomingEpisodes
